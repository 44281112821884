import React, {useCallback} from 'react';
import {useHistory} from 'react-router';

import {LoaderComponent, useCheckout3dSecure} from '@luft/core';

export function Checkout3dSecurePage() {
    const history = useHistory();

    const onSuccess = useCallback((token) => history.replace(`/checkout/success?token=${token}`), [history]);
    const onError = useCallback((error) => history.replace('/checkout', {error}), [history]);

    useCheckout3dSecure(onSuccess, onError);

    return (
        <LoaderComponent type="overlay"/>
    );
}
