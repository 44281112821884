import React from 'react';
import {createRoot} from 'react-dom/client';

import './critical.scss';
import './index.scss';

import {Root} from './Root';

const container = document.getElementById('root');
const root = createRoot(container);
// eslint-disable-next-line react/react-in-jsx-scope
root.render(<Root/>);
