import './App.scss';

import React, {useEffect} from 'react';
import {
    Route,
    Redirect,
    useHistory
} from 'react-router';

import {
    CmsBlockContainer,
    CmsFaviconContainer,
    CookieNoticeContainer,
    LuftRoute,
    LuftSwitch,
    useOnSessionExpired,
    useWarmMenuAndFirstPageCache
} from '@luft/core';

import {AppHeader} from './AppHeader';
import {AppFooter} from './AppFooter';
import {RedirectsSwitch} from './Redirects.switch';
import {AccountPage} from '../pages/AccountPage';
import {CmsPage} from '../pages/CmsPage';
import {CategoryPage} from '../pages/CategoryPage';
import {ProductPage} from '../pages/ProductPage';
import {ProductSearchPage} from '../pages/ProductSearchPage';
import {StaticHomePage} from '../pages/StaticHomePage';
import {CartPage} from '../pages/CartPage';
import {UserSocialLoginPage} from '../pages/UserSocialLoginPage';
import {Checkout3dSecurePage} from '../pages/Checkout3dSecurePage';
import {CheckoutSuccessPage} from '../pages/CheckoutSuccessPage';
import {CheckoutPage} from '../pages/CheckoutPage';
import {AuthenticatePage} from '../pages/AuthenticatePage';
import {ToastComponentLoadable as ToastComponent} from '../loadable/Toast.component';

type Props = {
    showCookieNotice?: boolean,
    showCmsHomePage?: boolean,
    isEnabledFaviconFromCms?: boolean
};

export function App(
    {
        showCookieNotice = true,
        showCmsHomePage = true,
        isEnabledFaviconFromCms = true
    }: Props) {
    const history = useHistory();
    const initialWarming = useWarmMenuAndFirstPageCache();
    useOnSessionExpired(() => history.push('/auth/login', {isExpired: true}));

    useEffect(() => {
        initialWarming();
    }, [initialWarming]);

    return (
        <div className="app">
            {isEnabledFaviconFromCms && <CmsFaviconContainer/>}
            {/* Custom CMS url redirects to Luft defaults */}
            <RedirectsSwitch/>
            <AppHeader/>
            <div className="app-content">
                <LuftSwitch>
                    {/* Overwrite Website root CMS page */}
                    {!showCmsHomePage && (
                        <Route path="/"
                               exact={true}
                               component={StaticHomePage}/>
                    )}
                    {/* Application routes */}
                    <Route path="/search"
                           component={ProductSearchPage}/>
                    <Route path="/cart"
                           component={CartPage}/>
                    <Route path="/account"
                           component={AccountPage}/>
                    <Route path="/auth"
                           component={AuthenticatePage}/>
                    <Route path="/checkout/3d-secure"
                           component={Checkout3dSecurePage}/>
                    <Route path="/checkout/success"
                           component={CheckoutSuccessPage}/>
                    <Route path="/checkout"
                           component={CheckoutPage}/>
                    <Route exact
                           path="/connect/social-login"
                           component={UserSocialLoginPage}/>
                    {/* URL Resolver routes */}
                    <LuftRoute entityType="CMS_PAGE"
                               component={CmsPage}/>
                    <LuftRoute entityType="CATEGORY"
                               component={CategoryPage}/>
                    <LuftRoute entityType="PRODUCT"
                               component={ProductPage}/>
                    {/* Routes, that can be overwritten by Url Rewrite */}
                    <Redirect to="/"/>
                </LuftSwitch>
            </div>
            <AppFooter/>
            <ToastComponent/>
            {showCookieNotice && (
                <CookieNoticeContainer>
                    <CmsBlockContainer cmsBlockId="cookies-notice"/>
                </CookieNoticeContainer>
            )}
        </div>
    );
}
