import './AppHeader.scss';

import React, {
    useEffect,
    useMemo,
    useState,
    useCallback
} from 'react';
import {
    useLocation,
    useHistory,
    useRouteMatch
} from 'react-router';
import {parse, stringify} from 'query-string';

import {
    CmsLogoContainer,
    DefaultMetaContainer,
    AccountNavControlComponentLoadable as AccountNavControlComponent,
    CartBarContainerLoadable as CartBarContainer,
    NavigationComponent,
    OfflineBarComponent,
    SearchComponent
} from '@luft/core';
import {useIsPushNotificationAvailable} from '@luft/push-notification';

import {MenuCategoriesContainerLoadable as MenuCategoriesContainer} from '../../loadable/MenuCategories.container';
import {PushNotificationSubscriptionContainerLoadable as PushNotificationSubscriptionContainer} from '../../loadable/PushNotificationSubscription.container';
import {SearchModalContainerLoadable} from '../../loadable/SearchModal.container';

export function AppHeader() {
    const history = useHistory();
    const location = useLocation();
    const shouldRenderControls = !useRouteMatch(['/cart', '/checkout']);
    const shouldRenderSearch = !useRouteMatch(['/account']);
    const isPushNotificationAvailable = useIsPushNotificationAvailable();

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const {search} = useMemo(() => parse(location.search), [location.search]);

    const closeMenu = useCallback(() => setIsMenuOpen(false), []);
    const toggleMenu = useCallback(() => setIsMenuOpen(prev => !prev), []);
    const navigateCart = useCallback(() => history.push('/cart'), [history]);
    const navigateAccount = useCallback(() => history.push('/account'), [history]);
    const navigateSearch = useCallback(s => history.push(`/search?${stringify({s})}`), [history]);

    useEffect(() => setIsMenuOpen(false), [location]);

    return (
        <div className="app-header">
            <div className="banner-new">
                Snap ONe banner 15:03
            </div>
            <DefaultMetaContainer/>
            <OfflineBarComponent/>
            <NavigationComponent expanded={isMenuOpen}
                                 onToggleExpanded={toggleMenu}
                                 onHide={closeMenu}
                                 title={<CmsLogoContainer title="LUFT"/>}
                                 controls={shouldRenderControls && (
                                     <>
                                         <AccountNavControlComponent onNavigate={navigateAccount}/>
                                         <CartBarContainer onCartTrigger={navigateCart}/>
                                     </>
                                 )}>
                <div className="app-header-content">
                    <MenuCategoriesContainer/>
                    <div className="separator"/>
                    {isPushNotificationAvailable && (
                        <PushNotificationSubscriptionContainer/>
                    )}
                </div>
            </NavigationComponent>
            {shouldRenderControls && shouldRenderSearch && (
                <SearchComponent resultsAs={SearchModalContainerLoadable}
                                 search={search}
                                 onNavigateSearchResults={navigateSearch}/>
            )}
        </div>
    );
}
