import './Root.scss';

import React, {useMemo} from 'react';
import {MagentoGQLCacheLink} from '@magento/peregrine/lib/Apollo/links/gqlCacheLink';

import {
    LuftBootComponent,
    PageBuilderComponentLoadable as PageBuilderComponent,
    PersistedQueryLink,
    useAuthLink,
    useIsInternalServerError,
    useInternalServerErrorLink
} from '@luft/core';
import {SlotsParserComponentLoadable as SlotsParserComponent} from '@luft/slots';
import {LuftPushNotificationProviderComponent} from '@luft/push-notification';

import {App} from '../App';
import {MaintenancePage} from '../pages/MaintenancePage';
import {RefreshModalComponentLoadable} from '../loadable/RefreshModal.component';

const {PUBLIC_URL} = process.env;

// Apollo Client GraphQL API endpoint
const {LUFT_APP_DATA_URI} = process.env;

// Functionality enablement flags
const {
    LUFT_APP_MULTISTORES,
    LUFT_APP_COOKIE_NOTICE,
    LUFT_APP_CACHE_WARMER,
    LUFT_APP_CMS_CONTENT_BLOCKS,
    LUFT_APP_CMS_CONTENT_PAGES,
    LUFT_APP_FAVICON_FROM_CMS,
    LUFT_APP_USE_CMS_HOMEPAGE,
    LUFT_APP_PUSH_NOTIFICATIONS,
    LUFT_APP_GTM
} = process.env;

// GTM configuration variables
const {
    LUFT_APP_WEBSITE_GTM_ID,
    LUFT_APP_WEBSITE_GTM_AUTH,
    LUFT_APP_WEBSITE_GTM_PREVIEW
} = process.env;

// Push notifications configuration variables
const {
    LUFT_APP_FIREBASE_API_KEY,
    LUFT_APP_FIREBASE_AUTH_DOMAIN,
    LUFT_APP_FIREBASE_DATABASE_URL,
    LUFT_APP_FIREBASE_PROJECT_ID,
    LUFT_APP_FIREBASE_STORAGE_BUCKET,
    LUFT_APP_FIREBASE_MESSAGING_SENDER_ID,
    LUFT_APP_FIREBASE_APP_ID,
    LUFT_APP_FIREBASE_MEASUREMENT_ID,
    LUFT_APP_WEBSITE_SERVICE_URL,
    LUFT_APP_WEBSITE_PUSH_ID
} = process.env;

// defines which component should be used for CMS pages and CMS blocks. Can be M2, SFCC or empty
const {
    LUFT_APP_CMS_RENDERER
} = process.env;

const CMS_RENDERERS = {
    M2: PageBuilderComponent,
    SFCC: SlotsParserComponent
};

type Props = {
    /**
     * Application start url. Used in static router for ssr.
     */
    url?: string,
    /**
     * Apollo HTTP link uri. Used in ssr mode.
     */
    dataUri?: string,
    /**
     * if App should work in ssr mode.
     */
    ssr?: boolean,
    /**
     * Custom fetch instance for Apollo
     */
    fetch?: Object
};

export function Root(props: Props) {
    const {
        url,
        dataUri = LUFT_APP_DATA_URI,
        ssr,
        fetch
    } = props;
    const internalServerErrorLink = useInternalServerErrorLink();
    const isInternalServerError = useIsInternalServerError();
    const authLink = useAuthLink();

    const config = useMemo(() => ({
        ssr,
        url,
        fetch,
        dataUri,
        serviceWorker: {
            refreshAs: RefreshModalComponentLoadable,
            swSrc: `${PUBLIC_URL}/service-worker.js`
        },
        cms: {
            enableBlocks: LUFT_APP_CMS_CONTENT_BLOCKS,
            enablePages: LUFT_APP_CMS_CONTENT_PAGES,
            renderer: CMS_RENDERERS[LUFT_APP_CMS_RENDERER]
        },
        stores: {
            enabled: LUFT_APP_MULTISTORES,
            links: [
                internalServerErrorLink,
                LUFT_APP_CMS_RENDERER === 'M2' && new MagentoGQLCacheLink(),
                new PersistedQueryLink({useGETForHashedQueries: true})
            ]
        },
        gtm: {
            enabled: LUFT_APP_GTM,
            gtmId: LUFT_APP_WEBSITE_GTM_ID,
            auth: LUFT_APP_WEBSITE_GTM_AUTH,
            preview: LUFT_APP_WEBSITE_GTM_PREVIEW
        },
        apollo: {
            links: [
                internalServerErrorLink,
                authLink,
                LUFT_APP_CMS_RENDERER === 'M2' && new MagentoGQLCacheLink(),
                new PersistedQueryLink({useGETForHashedQueries: true})
            ]
        },
        intl: {
            locale: 'en'
        },
        cacheWarmer: {
            enabled: LUFT_APP_CACHE_WARMER
        },
    }), [ssr, url, fetch, dataUri, internalServerErrorLink, authLink]);

    const firebaseConfig = useMemo(() => ({
        apiKey: LUFT_APP_FIREBASE_API_KEY,
        authDomain: LUFT_APP_FIREBASE_AUTH_DOMAIN,
        databaseURL: LUFT_APP_FIREBASE_DATABASE_URL,
        projectId: LUFT_APP_FIREBASE_PROJECT_ID,
        storageBucket: LUFT_APP_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: LUFT_APP_FIREBASE_MESSAGING_SENDER_ID,
        appId: LUFT_APP_FIREBASE_APP_ID,
        measurementId: LUFT_APP_FIREBASE_MEASUREMENT_ID
    }), []);

    const apnConfig = useMemo(() => ({
        webServiceUrl: LUFT_APP_WEBSITE_SERVICE_URL,
        websitePushId: LUFT_APP_WEBSITE_PUSH_ID
    }), []);

    return (
        <LuftBootComponent config={config}>
            <LuftPushNotificationProviderComponent enabled={LUFT_APP_PUSH_NOTIFICATIONS}
                                                   firebaseConfig={firebaseConfig}
                                                   apnConfig={apnConfig}>
                {isInternalServerError ? (
                    <MaintenancePage/>
                ) : (
                    <App showCookieNotice={LUFT_APP_COOKIE_NOTICE}
                         isEnabledFaviconFromCms={LUFT_APP_FAVICON_FROM_CMS}
                         showCmsHomePage={LUFT_APP_USE_CMS_HOMEPAGE}/>
                )}
            </LuftPushNotificationProviderComponent>
        </LuftBootComponent>
    );
}
